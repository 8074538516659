import useSecurityData from '../../../state/security/UseSecurityData';

const useHeader = () => {
  const { userInfo, currentNavItem, logout, closeSecondaryNav } = useSecurityData();

  const hasBeenClicked = () => {
    if (currentNavItem?.displayName) {
      closeSecondaryNav();
    }
  };

  const onLogoutClicked = (e) => {
    if (e?.preventDefault) {
      e.preventDefault();
    }
    logout();
  };

  return {
    isUnregisteredUser: userInfo?.isUnregistered === true,
    onLogoutClicked,
    hasBeenClicked
  };
};

export default useHeader;