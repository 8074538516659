import getBrowserFingerprint from 'get-browser-fingerprint';

import { NO_DATA_MESSAGE, SecurityHttpHelper } from '../../utils/HttpHelper';
import ToIntIfInt from '../../utils/ToIntIfInt';

const GetSecurityInfoForToken = async (taxonomies, scope, token, bustCache, state, setState) => {
  if ((state?.retries || 0) < 3 && state.isArrayLoading !== true) {
    let newState = {
      ...state,
      isObjLoading: true,
      isObjLoaded: false,
      message: 'Loading...'
    };
    setState(newState);

    const fingerprint = await getBrowserFingerprint({ hardwareOnly: false, debug: false })
      .catch((e) => { 
        console.error('Error getting fingerprint: ', e);
      });

    const postPayload = {
      access_token: token,
      toxonomies: taxonomies,
      scope,
      uIProjectName: import.meta.env.VITE_PROJECT_NAME,
      bustCache: bustCache ?? false,
      appName: import.meta.env.VITE_PROJECT_NAME === 'times-microsite-ui' ? 'Data' : 'OMR',
      deviceId: fingerprint
    };
    const url = '/Auth/GetSecurityInfoForToken';
    SecurityHttpHelper(url, 'POST', postPayload)
      .then((objData) => {
        if (!objData) {
          throw new Error(NO_DATA_MESSAGE);
        } else {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            userInfo: {
              ...objData,
              encryptedRequestId: objData.requestId ? ToIntIfInt(objData.requestId) * 13 : '',
              deviceId: fingerprint,
              isLoggedIn: objData?.firstName !== 'Anonymous',
              isUnregistered: objData?.firstName !== 'Unregistered'
            },
            message: '',
            retries: 0
          };
          setState(newState);
          if (objData?.requestId === '') {
            console.error('No Session Id returned from GetSecurityInfoForToken');
          }
        }
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        const retries = (newState.retries || 0) + 1;
        newState = {
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          userInfo: {},
          message: message,
          retries
        };
        setState(newState);
      });
  }
};

export default GetSecurityInfoForToken;