import { useState } from 'react';

import PingData from './PingData';

const usePingData = () => {
  const [ loginState, setLoginState ] = useState(PingData.INITIAL_STATE);
  // const [ sendVerificationCodeState, setSendVerificationCodeState ] = useState(PingData.INITIAL_STATE);
  const [ oneTimePasswordCheckState, setOneTimePasswordCheckState ] = useState(PingData.INITIAL_STATE);
  const [ passwordResetState, setPasswordResetState ] = useState(PingData.INITIAL_STATE);
  const [ setMFAState, setSetMFAState ] = useState(PingData.INITIAL_STATE);
  const [ forgotUsernameState, setForgotUsernameState ] = useState(PingData.INITIAL_STATE);
  const [ forgotPasswordState, setForgotPasswordState ] = useState(PingData.INITIAL_STATE);
  const [ changePasswordState, setChangePasswordState ] = useState(PingData.INITIAL_STATE);
  const [ registerStep1State, setRegisterStep1State ] = useState(PingData.INITIAL_STATE);
  const [ registerStep2State, setRegisterStep2State ] = useState(PingData.INITIAL_STATE);
  const [ registerStep3State, setRegisterStep3State ] = useState(PingData.INITIAL_STATE);
  
  const login = (username, password, environmentId, flowId) => {
    PingData.login(username, password, environmentId, flowId, loginState, setLoginState);
  };

  // const sendVerificationCode = (href, cookies) => {
  //   PingData.sendVerificationCode(href, cookies, sendVerificationCodeState, setSendVerificationCodeState);
  // }

  const oneTimePasswordCheck = (href, oneTimePassword, isServerSide) => {
    PingData.oneTimePasswordCheck(href, oneTimePassword, isServerSide, oneTimePasswordCheckState, setOneTimePasswordCheckState);
  }

  const passwordReset = (href, currentPassword, newPassword, isServerSide) => {
    PingData.passwordReset(href, currentPassword, newPassword, isServerSide, passwordResetState, setPasswordResetState);
  }

  const setMFA = (accessToken, email, textMessage, phoneNumber) => {
    PingData.setMFA(accessToken, email, textMessage, phoneNumber, setMFAState, setSetMFAState);
  }

  const forgotUsername = (emailAddress) => {
    PingData.forgotUsername(emailAddress, forgotUsernameState, setForgotUsernameState);
  }

  const forgotPassword = (username) => {
    PingData.forgotPassword(username, forgotPasswordState, setForgotPasswordState);
  };

  const changePassword = (href, recoveryCode, password) => {
    PingData.changePassword(href, recoveryCode, password, changePasswordState, setChangePasswordState);
  }

  const registerStep1 = (emailAddress) => {
    PingData.registerStep1(emailAddress, registerStep1State, setRegisterStep1State);
  }

  const registerStep2 = (emailAddress, verificationCodeFromServer, verificationCode) => {
    PingData.registerStep2(emailAddress, verificationCodeFromServer, verificationCode, registerStep2State, setRegisterStep2State);
  }

  const registerStep3 = (emailAddress, verificationCodeFromServer, verificationCode, username, password) => {
    PingData.registerStep3(emailAddress, verificationCodeFromServer, verificationCode, username, password, registerStep3State, setRegisterStep3State);
  }

  return {
    loginState,
    setLoginState,
    login,
    // sendVerificationCodeState,
    // setSendVerificationCodeState,
    // sendVerificationCode,
    oneTimePasswordCheckState,
    setOneTimePasswordCheckState,
    oneTimePasswordCheck,
    passwordResetState,
    setPasswordResetState,
    passwordReset,
    setMFAState,
    setSetMFAState,
    setMFA,
    forgotUsernameState,
    setForgotUsernameState,
    forgotUsername,
    forgotPasswordState,
    setForgotPasswordState,
    forgotPassword,
    changePasswordState,
    setChangePasswordState,
    changePassword,
    registerStep1State,
    setRegisterStep1State,
    registerStep1,
    registerStep2State,
    setRegisterStep2State,
    registerStep2,
    registerStep3State,
    setRegisterStep3State,
    registerStep3
  };
};

export default usePingData;