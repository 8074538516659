import { NO_DATA_MESSAGE } from '../../utils/HttpHelper';

  const setMFA = (accessToken, email, textMessage, phoneNumber, state, setState) => {
    if (state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) {
      let newState = {
        ...state,
        isObjLoading: true,
        isObjLoaded: false,
        message: 'Loading...'
      };
      setState(newState);
      
      fetch(`${import.meta.env.VITE_SECURITYAPI_URL}/Account/SetMFA`, {
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        method: "POST",
        body: JSON.stringify({
          email: email,
          textMessage: textMessage,
          phoneNumber: phoneNumber
        })
      })
      .then((response) => response.json())
      .then((json) => {
        newState = {
          ...newState,
          isObjLoading: false,
          isObjLoaded: true,
          objData: json,
          message: ''
        };
        setState(newState);
      })
      .catch((e) => {
        const message = e && e.message ? e.message : NO_DATA_MESSAGE;
        setState({
          ...newState,
          isObjLoading: false,
          isObjLoaded: false,
          message: message
        });
      });
    }
  };

  export default setMFA;