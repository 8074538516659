import React, { Fragment } from 'react';

import useAboutMember from './UseAboutMember';
import AboutMemberExistingMemberForm from './AboutMemberExistingMemberForm';
import AboutMemberNewMemberForm from './AboutMemberNewMemberForm';

import ExistingMemberSearchGrid from '../existingMemberSearch/ExistingMemberSearchGrid';

import Views from '../../Views';

import ViewTemplate from '../../components/viewTemplate/ViewTemplate';

import PopUpModal from '../../../common/components/dialogs/PopUpModal';
import SecondaryButton from '../../../common/components/buttons/SecondaryButton';
import PrimaryButton from '../../../common/components/buttons/PrimaryButton';

import { formatDate } from '../../../common/utils/DateFunctions';

import style from '../Omr.module.css';

const AboutMember = () => {
  const {
    isExistingUser,
    formState,
    errorState,
    omrPersonDupCheckState,
    showModal,
    onModalCanceled,
    onFormValueChanged,
    onValueTextPairChanged,
    onNextButtonClicked,
    onSelectMember,
    ineligibleMemberModalState,
    onIneligibleMemberModalCanceled,
    onNoneOfTheseClicked,
    usCitizenModalState,
    onUsCitizenshipIsChangingClicked,
    onUsCitizenshipIsNotChangingClicked
  } = useAboutMember();

  return (
    <Fragment>
      <ViewTemplate
        viewName={isExistingUser === true ? Views.ABOUT_MEMBER_EXISTING_MEMBER : Views.ABOUT_MEMBER_NEW_MEMBER}
        onNextButtonClicked={onNextButtonClicked}>
        {isExistingUser === true
          ? <AboutMemberExistingMemberForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged} />
          : <AboutMemberNewMemberForm
            formState={formState}
            errorState={errorState}
            onFormValueChanged={onFormValueChanged}
            onValueTextPairChanged={onValueTextPairChanged} />
        }
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title="Possible Existing Person Matches"
          displayPopUp={showModal === true}
          onModalCanceled={onModalCanceled}>
          <div className="row">
            <div className={['col-xs-12', 'usas-extra-bottom-margin', style.FontSize20].join(' ')}>
              It is possible that the person who you are registering is already in the system.  If you see
              them in the list below, please click on their name.
            </div>
            <div className={['col-xs-12', 'usas-extra-bottom-margin', 'usas-extra-top-margin', style.FontSize20].join(' ')}>
              If not click the "None of these are the person I am registering" button.
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <ExistingMemberSearchGrid
                state={omrPersonDupCheckState}
                onSelectMember={onSelectMember}
                showSearchResultCount={false} />
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" className="pull-right" onClick={onNoneOfTheseClicked}>None of these are the person I am registering</PrimaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={ineligibleMemberModalState.modalTitle}
          displayPopUp={ineligibleMemberModalState.displayPopup}
          onModalCanceled={onIneligibleMemberModalCanceled}>
          <div className='row'>
            <div className="col-xs-12">
              {ineligibleMemberModalState.selectedPerson && Object.keys(ineligibleMemberModalState.selectedPerson).length > 0 &&
                <Fragment>
                  <p className='text-center'><b>Selected Member:</b>&nbsp;{ineligibleMemberModalState.selectedPerson?.firstName} {ineligibleMemberModalState.selectedPerson?.middleName} {ineligibleMemberModalState.selectedPerson?.lastName} {ineligibleMemberModalState.selectedPerson?.suffix}{ineligibleMemberModalState.selectedPerson?.birthDate ? `(Birth Date: ${formatDate(ineligibleMemberModalState.selectedPerson?.birthDate)})` : ''}</p>
                  <p className='text-center'><b>Last Membership:</b>&nbsp;{`${ineligibleMemberModalState.selectedPerson?.orgUnitName || 'Non-USA Swimming Membership'} - (${ineligibleMemberModalState.selectedPerson?.orgUnitCode || 'Non-USAS'})`}{ineligibleMemberModalState.selectedPerson?.periodName ? ', ' + ineligibleMemberModalState.selectedPerson?.periodName : ''}</p>
                </Fragment>}
              <p className='text-center'>Your membership cannot be processed at this time, please contact USA Swimming for more information.</p>
            </div>
          </div>
          <div className="row usas-extra-top-margin">
            <div className={"col-xs-12 text-center"}>
              <SecondaryButton type="button" onClick={onIneligibleMemberModalCanceled}>Cancel</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
        <PopUpModal
          widthPct={90}
          maxWidth={720}
          title={usCitizenModalState.modalTitle}
          displayPopUp={usCitizenModalState.displayPopUp}>
          <div className="row">
            <div className="col-xs-12">
              <p>Are you sure that US Citizenship is changing?</p>
              <p>New US Citizen Selection: {formState.isUsCitizen === true ? 'Yes' : formState.isUsCitizen === false ? 'No' : ''}</p>
              <p><b>This change has an impact on National Team and National Junior Team Selection.</b></p>
            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 usas-extra-top-margin">
              <PrimaryButton type="button" onClick={onUsCitizenshipIsChangingClicked}>Yes, Accept Change</PrimaryButton>&nbsp;
              <SecondaryButton type="button" onClick={onUsCitizenshipIsNotChangingClicked}>No, Undo Change</SecondaryButton>
            </div>
          </div>
        </PopUpModal>
      </ViewTemplate>
    </Fragment>
  );
};

export default AboutMember;