import { NO_DATA_MESSAGE } from '../../utils/HttpHelper';

  const login = (username, password, environmentId, flowId, state, setState) => {
    if (state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) {
      let newState = {
        ...state,
        isObjLoading: true,
        isObjLoaded: false,
        message: 'Loading...'
      };
      setState(newState);
      
      if (environmentId === null && flowId === null) {
        // server-side login
        const url = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/Login`;
        fetch(url, {
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            username: username,
            password: password,
            environmentId: environmentId,
            flowId: flowId
          }),
          credentials: 'include'
        })
        .then((response) => response.json())
          .then((objData) => {
            if (!objData) {
              throw new Error(NO_DATA_MESSAGE);
            } else {
              newState = {
                ...newState,
                isObjLoading: false,
                isObjLoaded: true,
                objData,
                message: ''
              };
              setState(newState);
            }
          })
          .catch((e) => {
            const message = e && e.message ? e.message : NO_DATA_MESSAGE;
            setState({
              ...newState,
              isObjLoading: false,
              isObjLoaded: false,
              message: message
            });
          });
      }
      else {
        // client-side login
        fetch(`https://auth.pingone.com/${environmentId}/flows/${flowId}`, {
          headers: {
            "Content-Type": "application/vnd.pingidentity.usernamePassword.check+json"
          },
          method: "POST",
          body: JSON.stringify({
            username: username,
            password: password
          }),
          credentials: 'include'
        })
        .then((response) => response.json())
        .then((json) => {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData: json,
            message: ''
          };
          setState(newState);
        })
        .catch((e) => {
          const message = e && e.message ? e.message : NO_DATA_MESSAGE;
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: false,
            message: message
          });
        });
      }
    }
  };

  export default login;