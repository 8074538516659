import useSessionEnding from "./UseSessionEnding";

import Login from "./Login";
import PopUpModal from "../../../dialogs/PopUpModal";
import StopwatchIcon from "../../../icons/StopwatchIcon";
import ExclamationIcon from "../../../icons/ExclamationIcon";

const SessionEnding = ({ setShowSessionEnding }) => {
  const { onModalClosed, state } = useSessionEnding(setShowSessionEnding);

  return (
    <PopUpModal
      displayPopUp={true}
      widthPct={90}
      maxWidth={720}
      onModalCanceled={() => onModalClosed(true)}
      title={`Your Session Expires In ${state.expiringIn}`}>
      <div className="row" style={{ display: 'flex' }}>
        <div className="col-xs-12 col-sm-8">
          <div className="usas-extra-bottom-margin">
            <b>If you would like to keep working, please login again.</b><br /><br />
            Otherwise, you will be redirected to the login page when your session expires in {state.expiringIn}.
          </div>
          <Login onModalClosed={onModalClosed} />
        </div>
        <div className="hidden-xs col-sm-4" style={{ borderLeft: 'solid 1px #eee', display: 'flex', flexDirection: 'column' }}>
          <div style={{ color: '#041E42', fontWeight: 'bold' }}>
            <StopwatchIcon />&nbsp;Your session expires...<br /><br />
          </div>
          <table cellPadding="4" style={{ width: '100%' }}>
            <tbody>
              <tr>
                <td align="center" style={{ backgroundColor: '#041E42', borderRadius: 5, color: '#ffffff', fontWeight: 500, lineHeight: '40px', width: '33%' }}>
                  {state.expiringIn.substring(0, 2)}
                </td>
                <td align="center" style={{ color: '#041E42', padding: 4 }}>
                  :
                </td>
                <td align="center" style={{ backgroundColor: '#041E42', borderRadius: 5, color: '#ffffff', fontWeight: 500, lineHeight: '40px', width: '33%' }}>
                  {state.expiringIn.substring(3, 5)}
                </td>
                <td align="center" style={{ color: '#041E42', padding: 4 }}>
                  :
                </td>
                <td align="center" style={{ backgroundColor: '#041E42', borderRadius: 5, color: '#ffffff', fontWeight: 500, lineHeight: '40px', width: '33%' }}>
                  {state.expiringIn.substring(6, 8)}
                </td>
              </tr>
              <tr>
                <td align="center" style={{ color: '#041E42', fontSize: '0.8em' }}>
                  <b>hours</b>
                </td>
                <td align="center">
                </td>
                <td align="center" style={{ color: '#041E42', fontSize: '0.8em' }}>
                  <b>minutes</b>
                </td>
                <td align="center">
                </td>
                <td align="center" style={{ color: '#041E42', fontSize: '0.8em' }}>
                  <b>seconds</b>
                </td>
              </tr>
            </tbody>
          </table>
          <br />
          <div style={{ color: 'darkorange' }}>
            <div style={{ fontWeight: 'bold' }}>
              <ExclamationIcon />&nbsp;For your consideration...<br /><br />
            </div>
            <div>
              If you are in the middle of extensive work and choose not to login again, you may lose your progress, and you may have to start over.
            </div>
          </div>
        </div>
      </div>
    </PopUpModal>
  );
};

export default SessionEnding;