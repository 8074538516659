import React from 'react';

import Headings from '../headings/Headings';

const LoginInstructions = ({ textAlign, hideHeading }) => {
    return (
        <div className="row usas-extra-top-margin">
            <div className="col-xs-12 usas-extra-top-margin" align={textAlign === 'left' ? 'left' : "center"}>
                {hideHeading !== true && (
                    <Headings.H5>Need help? Refer to the following resources:</Headings.H5>
                )}
                <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1662596019661" target="_blank" rel="noreferrer">If you are a parent about to register your child, please create your account first - ARTICLE</a></p>
                <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1661958692911" target="_blank" rel="noreferrer">How do I set up my new SWIMS account if I already have a membership? - ARTICLE</a></p>
                <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1693622242502" target="_blank" rel="noreferrer">How do I register someone who is brand new to USA Swimming? - ARTICLE</a></p>
                <p><a href="https://usaswimming.thecloudtutorialusers.com/main/articles/1661909149730" target="_blank" rel="noreferrer">How do I create an account in SWIMS as a new member? - ARTICLE</a></p>
                <p><a href="https://www.youtube.com/watch?v=aI-OorgUGp4" target="_blank" rel="noreferrer">How do I register a new member? - VIDEO TUTORIAL</a></p>
            </div>
        </div>
    );
};

export default LoginInstructions;