import { BASIC_INITIAL_STATE } from '../../utils/HttpHelper';

import login from './Login';
// import sendVerificationCode from './SendVerificationCode';
import oneTimePasswordCheck from './OneTimePasswordCheck';
import passwordReset from './PasswordReset';
import setMFA from './SetMFA';
import forgotUsername from './ForgotUsername';
import forgotPassword from './ForgotPassword';
import changePassword from './ChangePassword';
import registerStep1 from './RegisterStep1';
import registerStep2 from './RegisterStep2';
import registerStep3 from './RegisterStep3';

const PingData = {
  INITIAL_STATE: { ...BASIC_INITIAL_STATE },
  login,
  // sendVerificationCode,
  oneTimePasswordCheck,
  passwordReset,
  setMFA,
  forgotUsername,
  forgotPassword,
  changePassword,
  registerStep1,
  registerStep2,
  registerStep3
};

export default PingData;
