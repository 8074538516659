import { SecurityHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

  const registerStep1 = (emailAddress, state, setState) => {
    if (state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) {
      let newState = {
        ...state,
        isObjLoading: true,
        isObjLoaded: false,
        message: 'Loading...'
      };
      setState(newState);
      
      const url = `/Account/RegisterStep1`;
      SecurityHttpHelper(url, 'POST', {
        emailAddress: emailAddress
      })
        .then((objData) => {
          if (!objData) {
            throw new Error(NO_DATA_MESSAGE);
          } else {
            newState = {
              ...newState,
              isObjLoading: false,
              isObjLoaded: true,
              objData,
              message: ''
            };
            setState(newState);
          }
        })
        .catch((e) => {
          const message = e && e.message ? e.message : NO_DATA_MESSAGE;
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: false,
            message: message
          });
        });
    }
  };

  export default registerStep1;