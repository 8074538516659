import { Fragment } from 'react';

import useHeader from './UseHeader';

import Shield from '../Shield';
import TopHeader from './topHeader/TopHeader';
import BottomHeader from './bottomHeader/BottomHeader';
import SessionEnding from './sessionEnding/SessionEnding';
import TitlePic from './dataHubSwimmerIcon/DataHubSwimmerIconPic';
// import SessionDebugTimer from './sessionEnding/SessionDebugTimer';

import UserIcon from '../../icons/UserIcon';

import style from './Header.module.css';

const Header = ({ showSessionEnding, setShowSessionEnding }) => {
  const { isUnregisteredUser, hasBeenClicked, onLogoutClicked } = useHeader();

  if (isUnregisteredUser === true) {
    return (
      <header>
        <Shield className={style.Shield} />
        <nav className={style.TopHeader}>
          <ul>
            <li><button onClick={onLogoutClicked}><UserIcon tooltip="Logout" />&nbsp;Logout</button></li>
          </ul>
        </nav>
        <div className={[style.LargeBottomHeader, 'hidden-xs hidden-sm'].join(' ')}>
          <div className={style.Primary}>
            &nbsp;
          </div>
        </div>
        <div className={[style.SmallBottomHeader, 'visible-xs visible-sm'].join(' ')}>
          &nbsp;
        </div>
      </header>
    );
  }

  return (
    <Fragment>
      <header>
        <Shield className={style.Shield} />
        {/* <SessionDebugTimer /> */}
        <TopHeader />
        <BottomHeader />
        {showSessionEnding === true && <SessionEnding setShowSessionEnding={setShowSessionEnding} />}
      </header>
      {import.meta.env.VITE_TIMES_MICROSITE_UI_DOMAIN === 'self' &&
        <div className={style.DataHubContainer} onClick={hasBeenClicked}>
          <img width={'100%'} height={'300px'} src={`${import.meta.env.VITE_CDN_URL}/UnderwaterLaneLines.jpg`} />
          <div className={style.DataHubCenteredText}>
            <span><TitlePic /><span className='hidden-xs'>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span><span className={style.DataHubTitleFont}>Data&nbsp;Hub</span></span>
          </div>
        </div>
      }
    </Fragment>
  );
};

export default Header;