import { useEffect, useState } from 'react';

import { getStoredTokenObj } from '../../../../utils/TokenUtils';

const useSessionEnding = (setShowSessionEnding) => {
  const [state, setState] = useState({ expiringIn: '00:00:00' });

  const onModalClosed = (disableSessionEndingPopup) => {
    setShowSessionEnding(false, disableSessionEndingPopup);
  };

  const calculateExpiringIn = () => {
    const tokenExpiration = getStoredTokenObj().expirationDatetime;
    let result = '00:00:00';

    if (Number.isInteger(Date.parse(tokenExpiration))) {
      const expirationDatetime = new Date(Date.parse(tokenExpiration));
      const now = new Date();
      let ms = expirationDatetime.getTime() - now.getTime();
      const hours = Math.floor(ms / (60 * 60 * 1000));
      ms -= hours * (60 * 60 * 1000);
      const minutes = Math.floor(ms / (60 * 1000));
      ms -= minutes * (60 * 1000);
      const seconds = Math.floor(ms / (1000));
      result = `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds < 0 ? '0' : seconds}`;
    }

    setState({
      ...state,
      expiringIn: result
    });
  };

  useEffect(() => {
    calculateExpiringIn();
    const interval = setInterval(() => calculateExpiringIn(), 1000);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    onModalClosed,
    state
  };
};

export default useSessionEnding;