import React, { Fragment } from 'react';
import Headings from '../headings/Headings';
import LoginInstructions from '../loginInstructions/LoginInstructions';
import YesNoSwitch from '../yesNoSwitch/YesNoSwitch';
import LinkById from './LinkById';
import LinkByPersonalInfo from './LinkByPersonalInfo';
import usePersonCreation from './UsePersonCreation';
import PopUpModal from '../dialogs/PopUpModal';
import PrimaryButton from '../buttons/PrimaryButton';

const PersonCreation = () => {
  const {
    hasPriorMembership,
    showLinkById,
    showLinkByPersonalInfo,
    showCreateAccountDialog,
    createAccountDialogText,
    onHasPriorMembershipChanged,
    onUseAdditionalInfoClicked,
    goToLinkById,
    onComplete,
    okClicked } = usePersonCreation();

  return (
    <Fragment>
      <div className="row usas-extra-top-margin">
        <div className="col-xs-12">
          <Headings.H3>Finalize Your Login Account</Headings.H3>
          <p>
            Please supply the following information to finish creating your login account.  You
            will be redirected back to where you started when this is completed.
          </p>
        </div>
      </div>
      <div className="row usas-extra-top-margin usas-extra-bottom-margin">
        <div className="col-xs-12 col-sm-6">
          <YesNoSwitch
            isHorizontal={true}
            label="Have you ever had a USA Swimming Membership?"
            name="hasPriorMembership"
            checked={hasPriorMembership}
            onChange={onHasPriorMembershipChanged} />
        </div>
      </div>

      {showLinkById === true && <LinkById onUseAdditionalInfoClicked={onUseAdditionalInfoClicked} onComplete={onComplete} />}
      {showLinkByPersonalInfo === true && <LinkByPersonalInfo goToLinkById={goToLinkById} onComplete={onComplete} hasPriorMembership={hasPriorMembership} />}
      <LoginInstructions textAlign={'left'} />
      <PopUpModal
        widthPct={50}
        maxWidth={500}
        title="Information..."
        displayPopUp={showCreateAccountDialog === true && createAccountDialogText !== ''}>
        <div className="row">
          <div className="col-xs-12 usas-extra-bottom-margin">
            <p dangerouslySetInnerHTML={{ __html: createAccountDialogText }}></p>
          </div>
          <div className="col-xs-12">
            <p align="center"><PrimaryButton onClick={(e) => okClicked(e)}>OK</PrimaryButton></p>
          </div>
        </div>
      </PopUpModal>
    </Fragment>
  );
};

export default PersonCreation;