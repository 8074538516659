import { SecurityHttpHelper, NO_DATA_MESSAGE } from '../../utils/HttpHelper';

  const passwordReset = (href, currentPassword, newPassword, isServerSide, state, setState) => {
    if (state.isObjLoading !== true && state.isObjLoaded !== true && !state.message) {
      let newState = {
        ...state,
        isObjLoading: true,
        isObjLoaded: false,
        message: 'Loading...'
      };
      setState(newState);
      
      if (isServerSide === true) {
        // server-side password reset
        const url = `${import.meta.env.VITE_SECURITYAPI_URL}/Account/PasswordReset`;
        fetch(url, {
          headers: {
            "Content-Type": "application/json"
          },
          method: "POST",
          body: JSON.stringify({
            href: href,
            currentPassword: currentPassword,
            newPassword: newPassword
          }),
          credentials: 'include'
        })
        .then((response) => response.json())
        .then((json) => {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData: json,
            message: ''
          };
          setState(newState);
        })
        .catch((e) => {
          const message = e && e.message ? e.message : NO_DATA_MESSAGE;
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: false,
            message: message
          });
        });
      }
      else {
        // client-side password reset
        fetch(href, {
          headers: {
            "Content-Type": "application/vnd.pingidentity.otp.check+json"
          },
          method: "POST",
          body: JSON.stringify({
            currentPassword: currentPassword,
            newPassword: newPassword
          }),
          credentials: 'include'
        })
        .then((response) => response.json())
        .then((json) => {
          newState = {
            ...newState,
            isObjLoading: false,
            isObjLoaded: true,
            objData: json,
            message: ''
          };
          setState(newState);
        })
        .catch((e) => {
          const message = e && e.message ? e.message : NO_DATA_MESSAGE;
          setState({
            ...newState,
            isObjLoading: false,
            isObjLoaded: false,
            message: message
          });
        });
      }
    }
  };

  export default passwordReset;